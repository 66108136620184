import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['menu', 'toggle', 'content']

  connect() {
    this._closeOnClickOutside = this._closeOnClickOutside.bind(this)
    document.addEventListener('click', this._closeOnClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this._closeOnClickOutside)
  }

  toggle(event) {
    event.stopPropagation()

    const isAlreadyOpen = this.toggleTarget.classList.contains("open");

    if (!isAlreadyOpen) {
      this.toggleAllOff()
    }

    this.toggleTarget.classList.toggle("open")
    this.menuTarget.classList.toggle("hidden")
  }

  toggleContent(event) {
    event.stopPropagation()

    this.contentTarget.classList.toggle('hidden')
  }

  toggleAllOff() {
    const dropdowns = document.querySelectorAll('[data-controller="dropdown"]')
    dropdowns.forEach(dropdown => {
      const menu = dropdown.querySelector('[data-dropdown-target="menu"]')
      const toggle = dropdown.querySelector('[data-dropdown-target="toggle"]')
      if (menu && toggle) {
        menu.classList.add("hidden")
        toggle.classList.remove("open")
      }
    })
  }

  _closeOnClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add("hidden")
      this.toggleTarget.classList.remove("open")
    }
  }
}
