import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mycotoxin-threats"
export default class extends Controller {
  static targets = ["display", "content", "triggerButton", "wrapper"]

  displayContent(event) {
    this.triggerButtonTargets.forEach(button => {
      button.style.backgroundColor = "#fff"
    })
    event.currentTarget.style.backgroundColor = "#E4F1DC"

    const mycotoxin = event.currentTarget.dataset.mycotoxin;
    const contentElement = this.contentTargets.find(content => content.dataset.mycotoxin === mycotoxin)

    if (contentElement) {
      const content = contentElement.innerHTML

      this.displayTarget.innerHTML = content
    }

    this.wrapperTarget.scrollIntoView({ behavior: "smooth" });

  }
}
