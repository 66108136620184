// app/javascript/controllers/risk_assessment_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["specy", "materials", "template"]

  updateAnimals(event) {
    const specyId = event.target.value
    const model = this.element.dataset.model
    const url = `/en/refresh_animal?specy_id=${specyId}&model=${model}`

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
