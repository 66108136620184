import {Controller} from "@hotwired/stimulus"
import L from "leaflet"
import "leaflet/dist/leaflet.css";

// Constants
const TILE_LAYER = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
const WORLD_DEFAULT_ZOOM = 3
const WORLD_MIN_ZOOM = 3
const WORLD_MAX_ZOOM = 4

const COUNTRY_MAX_ZOOM = 10

const WORLD_CENTER = [0, -1]

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["container", "filters", "emptyState", "loadingState", "material", "toxin"]

  connect() {
    this.locale = this.element.dataset.locale
    this.back_label = this.element.dataset.backLabel
    this.more_info_label = this.element.dataset.moreInfoLabel
    this.map_type = this.element.dataset.mapType

    // watch for the material filter if the map type is contamination
    if (this.map_type === "contamination") {
      if(this.filtersTarget.querySelector("#filters_source").value === "finished-feed") {
        this.filtersTarget.querySelector(".filters_material_id").classList.toggle("hidden")
      }

      this.filtersTarget.querySelector("#filters_source").addEventListener("change", (event) => {
        this.filtersTarget.querySelector(".filters_material_id").classList.toggle("hidden")
      })
    }

    // Event listener for the filters form on submit
    this.filtersTarget.addEventListener("submit", (event) => {
      event.preventDefault()
      this.showWorld(event)
    })

    this.initMap()
    this.view = "world"
  }

  disconnect() {
    this.map.remove()
  }

  filters_url() {
    return `${this.filtersTarget.action}.json?${new URLSearchParams(new FormData(this.filtersTarget))}`

  }

  initMap() {
    // Create a map in the "map" div, set the view to a given place and zoom
    this.map = L.map(this.containerTarget).setView(WORLD_CENTER, WORLD_DEFAULT_ZOOM)
    // Add an OpenStreetMap tile layer
    L.tileLayer(TILE_LAYER, {
      minZoom: WORLD_MIN_ZOOM,
      maxZoom: COUNTRY_MAX_ZOOM,
    }).addTo(this.map)

    this.map.setMaxBounds(L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)))
    this.map.setMaxZoom(WORLD_MAX_ZOOM)
    this.loadGeoJson()
    this.pane = this.map.createPane("fixed", this.containerTarget)
  }

  // Fetch the geojson data from the server
  // and add it to the map
  loadGeoJson() {
    this.emptyStateTarget.classList.add("hidden")
    this.loadingStateTarget.classList.remove("hidden")
    if (this.map_type === "predict") {
      this.toxinTarget.disabled = false;
    }
    fetch(this.filters_url())
      .then((response) => response.json())
      .then((data) => {
        // Remove world layer if it exists
        if (this.worldLayer) {
          this.map.removeLayer(this.worldLayer)
        }

        if (data.geo_json.features.length === 0) {
          this.emptyStateTarget.classList.remove("hidden")
          return
        }

        if(data.year) {
          document.getElementById("predict_year").textContent = data.year
        }

        this.worldLayer = this.addWorldGeoJson(data.geo_json)
      })
      .catch((error) => {
        console.error("Error loading geojson data", error)
      }).finally(() => {
      // Remove the disabled attribute from the button
      this.filtersTarget.querySelector("input[type='submit']").removeAttribute("disabled")
      this.loadingStateTarget.classList.add("hidden")
      if (this.map_type === "predict") {
        if (this.materialTarget.options[this.materialTarget.selectedIndex].text === "Wheat") {
          this.toxinTarget.disabled = true;
        } else {
          this.toxinTarget.disabled = false;
        }
      }

    })
  }

  showCountry(event) {
    if (this.map_type === "contamination") {
      return
    }

    event.preventDefault()
    const countryId = event.target.dataset.countryId
    this.toxinTarget.disabled = false;
    fetch(`/${this.locale}/worldwide_occurences/predicts/${countryId}.json?${new URLSearchParams(new FormData(this.filtersTarget))}`)
      .then((response) => response.json())
      .then((data) => {
        // Add the country layer
        this.countryLayer = this.addCountryGeoJson(data.geo_json)

        // Hide the world layer
        this.worldLayer.eachLayer((layer) => {
          layer.setStyle({
            weight: 0,
            fillOpacity: 0,
          })
        })

        // Modify the zoom settings
        this.map.setMaxZoom(COUNTRY_MAX_ZOOM)

        this.map.fitBounds(this.countryLayer.getBounds())

        // Set the min zoom to the current zoom
        setTimeout(
          () => this.map.setMinZoom(this.map.getZoom()),
          500
        )

        this.view = "country"

        // Loop over all the popups to edit the button
        this.setPopupButtonToBack()

        // Remove the world layer
        //this.map.removeLayer(this.worldLayer)
      })
      .catch((error) => {
        console.error("Error loading country data", error)
      })
      if (this.materialTarget.options[this.materialTarget.selectedIndex].text === "Wheat") {
        this.toxinTarget.disabled = true;
      } else {
        this.toxinTarget.disabled = false;
      }
  }

  showWorld(event) {
    event.preventDefault()
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        layer.remove()
      }
    })

    this.map.eachLayer((layer) => {
      if (layer instanceof L.GeoJSON) {
        layer.remove()
      }
    })

    this.loadGeoJson()
    this.map.setMaxZoom(WORLD_MAX_ZOOM)
    this.map.setMinZoom(WORLD_MIN_ZOOM)
    this.map.setView(WORLD_CENTER, WORLD_DEFAULT_ZOOM)
    this.view = "world"
  }

  createPopupForFeature(feature) {
    // Copy the #popup-content element
    const popupContent = document.getElementById("popup-content").cloneNode(true)

    // Loop over the features.properties to replace the placeholders
    Object.keys(feature.properties).forEach((key) => {
      const value = feature.properties[key]
      if (key != "color") {
        let data_label = popupContent.querySelector(`[data-key="${key}"]`)
        if (data_label) {
          data_label.textContent = value
        }
      } else {
        let data_value = popupContent.querySelector(`[data-value="${value}"]`)
        if (data_value) {
          data_value.classList.remove('[&>svg]:hidden')
        }
      }
    })

    // replace all ":countryid" with the actual country id
    popupContent.innerHTML = popupContent.innerHTML.replace(/:countryid/g, feature.properties.id)

    // Remove the hidden attribute from the popup
    popupContent.classList.remove("hidden")

    return L.popup({
      pane: "fixed",
      className: "popup-fixed",
      autoPan: false,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    }).setContent(popupContent).on("add", () => {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Popup && layer._source.feature.properties.id !== feature.properties.id) {
          layer.remove()
        }
      })
    })
  }


  // Add the world geojson data to the map
  addWorldGeoJson(geoJsonData) {
    return L.geoJSON(geoJsonData, {
      onEachFeature: (feature, layer) => {
        layer.setStyle({
          fillColor: feature.properties.color,
          weight: 1,
          color: feature.properties.color,
          fillOpacity: 0.2,
        })
        layer.bindPopup(this.createPopupForFeature(feature))
        layer.on("click", (e) => {
          this.setPopupButtonToBack()
        })
      },
    }).addTo(this.map)
  }

  setPopupButtonToBack() {
    if (this.view !== "country") return

    this.map.eachLayer((layer) => {
      if (layer instanceof L.Popup) {
        const button = layer._contentNode.querySelector("button")
        if (button) {
          //remove focus from the button
          button.blur()

          // Change the content of the button to "Back"
          button.innerHTML = this.back_label

          // Remove the disabled attribute
          button.removeAttribute("disabled")

          // Set the data-action to "map#showWorld"
          button.dataset.action = "map#showWorld"
        }
      }
    })
  }

  // Add the country geojson data to the map
  addCountryGeoJson(geoJsonData) {
    return L.geoJSON(geoJsonData, {
      onEachFeature: (feature, layer) => {
        layer.setStyle({
          fillColor: feature.properties.color,
          weight: 1,
          color: feature.properties.color,
          fillOpacity: 0.2,
        })

        layer.on("click", (e) => {
          const detailsContent = document.getElementById(`popup-content__country-details-${feature.properties.country_id}`)
          if (detailsContent) {
            detailsContent.classList.remove("hidden")
            Object.keys(feature.properties).forEach((key) => {
              const value = feature.properties[key]
              if (key != "color") {
                let data_label = detailsContent.querySelector(`[data-key="${key}"]`)
                if (data_label) {
                  data_label.textContent = value
                }
              } else {
                let data_value = detailsContent.querySelector(`[data-value="${value}"]`)
                let chart_levels = detailsContent.getElementsByClassName('chart-level')
                for (let i = 0; i < chart_levels.length; i++) {
                  chart_levels[i].classList.add('hidden');
                }
                if (data_value) {
                  data_value.classList.remove('hidden')
                }
              }
            })
          }
        })
      },
    }).addTo(this.map)
  }
}
