import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "message"]

  connect() {
    this.checkValues()
  }

  checkValues() {
    let count = 0;

    this.inputTargets.forEach((input) => {
      if (parseFloat(input.value) > 0) {
        count++;
      }
    });

    if (count >= 2) {
      this.messageTarget.style.display = 'block';
    } else {
      this.messageTarget.style.display = 'none';
    }
  }

  inputChanged() {
    this.checkValues();
  }
}
