import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-inputs"
export default class extends Controller {
  static targets = ["sapNumber", "sapNumberWrapper", "suppliedByCheckboxes"]

  connect() {
    this.showIfChecked = this.sapNumberTarget.dataset.showIfChecked
    if (this.suppliedByCheckboxesTargets.find(e => e.id === 'user_supplied_by_adisseo').checked) {
      this.sapNumberWrapperTarget.classList.remove("hidden")
    } else {
      this.sapNumberWrapperTarget.classList.add("hidden")
    }
  }

  toggle_sap_number_input(event) {
    if (event.currentTarget.value === this.showIfChecked) {
      this.sapNumberWrapperTarget.classList.remove("hidden")
    } else {
      this.sapNumberWrapperTarget.classList.add("hidden")
    }
  }
}
