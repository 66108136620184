import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["menu", "iconMenu", "iconClose"]

  connect() {
    this.body = document.body
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden")
    this.menuTarget.classList.toggle("open")
    this.iconMenuTarget.classList.toggle("hidden")
    this.iconCloseTarget.classList.toggle("hidden")

    if (this.menuTarget.classList.contains("hidden")) {
      this.enableBodyScroll()
    } else {
      this.disableBodyScroll()
    }
  }

  disableBodyScroll() {
    this.body.style.height = "100vh"
    this.body.style.overflow = "hidden"
  }

  enableBodyScroll() {
    this.body.style.height = ""
    this.body.style.overflow = ""
  }
}
