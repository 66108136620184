import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.renderChart();
  }

  renderChart() {
    let questions_labels = [];
    feedmill_data.sections.forEach(section => {
      section.questions.forEach(question => {
        if (!questions_labels.includes(question.question)) {
          questions_labels.push(question.question);
        }
      });
    });

    let datasets = feedmill_data.sections.map((section, index) => {
      let scores = questions_labels.map(question => {
        let q = section.questions.find(q => q.question === question);
        return q ? q.score : null;
      });

      return {
        label: section.name,
        data: scores,
        fill: true,
        backgroundColor: `rgba(${index * 50 % 255}, ${index * 100 % 255}, ${index * 150 % 255}, 0.2)`,
        borderColor: `rgb(${index * 50 % 255}, ${index * 100 % 255}, ${index * 150 % 255})`,
        pointBackgroundColor: `rgb(${index * 50 % 255}, ${index * 100 % 255}, ${index * 150 % 255})`,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: `rgb(${index * 50 % 255}, ${index * 100 % 255}, ${index * 150 % 255})`
      };
    });

    const ctx = this.containerTarget.querySelector('canvas').getContext('2d');
    new Chart(ctx, {
      type: 'radar',
      data: {
        labels: questions_labels,
        datasets: datasets
      },
      options: {
        scales: {
          r: {
            min: 0,
            ticks: {
              beginAtZero: true,
              stepSize: 1
            }
          }
        },
        elements: {
          line: {
            borderWidth: 3
          }
        }
      }
    });
  }
}
